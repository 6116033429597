import React from 'react';
import Article, { SubTitle } from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import timeline1 from 'img/help/timeline/timeline1.png';
import timeline2 from 'img/help/timeline/timeline2.png';
import timeline3 from 'img/help/timeline/timeline3.png';
import timeline4 from 'img/help/timeline/timeline4.png';
import timeline5 from 'img/help/timeline/timeline5.png';

const Content = () => {
  return (
    <div>
      <SubTitle>Timeline - what is it?</SubTitle>
      <p>
        The Timeline helps you to see chronological and grouped by page list of events correlated
        with session replay.
      </p>
      <SubTitle>What does the Timeline look like?</SubTitle>

      <p>
        Go to the sessions tab and then choose a record that you are interested in. You can use our{' '}
        <a href="/help/how-does-the-search-work/">Search</a> to limit sessions results. For example
        you can find mobile sessions from one particular country.
      </p>
      <Image src={timeline1} lazy alt="LiveSession App - Timeline tab" title="Switch to Timeline" />

      <p>
        After clicking, the record details are on the center along with <b>timeline</b> on the right
        side. You can see events which happened chronologically. <b>Timeline</b> is divided into
        sections by viewed page. If you want to jump to the moment when the event happened and see
        results on the player just click on the event box.
      </p>
      <Image src={timeline2} lazy alt="LiveSession App - Timeline" title="LiveSession Timeline" />

      <p>
        After clicking a specific event you can see details. We show the exact date of the event
        with metadata if it has any. For example &quot;Clicked&quot; event has element property with
        particular element.
      </p>
      <Image
        src={timeline3}
        lazy
        alt="LiveSession App - Timeline - Details"
        title="LiveSession Timeline details"
      />

      <p>
        Sometimes you need to find a session based on some criteria (as be mentioned above).
        LiveSession lets you show that on <b>timeline</b> also. To do that, choose filters and terms
        that you are interested in.
      </p>
      <Image
        src={timeline4}
        lazy
        alt="LiveSession App - Timeline tab - terms"
        title="Switch to Timeline with terms"
      />

      <p>After that, you can see marked events with terms of what you chose like in step before.</p>
      <Image
        src={timeline5}
        lazy
        alt="LiveSession App - Timeline - Details - Terms"
        title="LiveSession Timeline details with terms"
      />
    </div>
  );
};

const related = [
  {
    link: '/help/what-is-a-session/',
    title: 'What is a session?',
    description: 'A session is a set of the user’s interactions with a website within a timeframe.',
  },
  {
    link: '/help/how-does-the-search-work/',
    title: 'How does the Search work?',
    description: 'See how Search does looks like and how it works.',
  },
];

export const frontmatter = {
  metaTitle: 'How does the Timeline work?',
  metaDescription: 'Wondering about the usability of our Timeline? Read here.',
  canonical: '/help/how-does-the-timeline-work/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
